import { useEffect, useState } from "react";
import "./App.css";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import startShow from "./light-show";
import Chart from "react-apexcharts";

function App() {
  const [showNextButton, setShowNextButton] = useState(false);
  const [notes, setNotes] = useState([]);
  const [showEnterData, setShowEnterData] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showDownload, setShowDownload] = useState(false);
  const [newsSource, setNewsSource] = useState(null);
  const [limit, setLimit] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [csvContent, setCsvContent] = useState(null);
  const [performance, setPerformance] = useState(null);
  const [isBroadcasted, setIsBroadcasted] = useState(false);
  useEffect(() => {
    if (newsSource !== null) {
    }
  }, []);
  useEffect(() => {
    if (csvContent !== null && csvContent !== undefined) {
      setLoading(false);
    }
  }, [csvContent]);

  const LineChart = (data) => {
    function parseDuration(durationString) {
      const [hours, minutes, seconds] = durationString.split(":").map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    }
    let csvArr = data.data.split("\n");
    csvArr.shift();
    csvArr.pop();
    console.log(csvArr);
    const SEQUENCE = csvArr.map((item) => {
      const [name, info, unparsedDuration] = item.split(",");
      let intensity = Number(info.match(/at (\d+)/)[1]);
      let fade = Number(info.match(/fade (\d+)/)[1]);
      const parsedDuration = parseDuration(unparsedDuration);
      let startTime = parsedDuration;

      return { name, intensity, fade, startTime };
    });
    SEQUENCE.unshift({ name: "M0", intensity: 0, fade: 0, startTime: 0 });
    var series = [
      {
        name: "Intensity",
        data: [[0, 0]],
      },
    ];
    var prevItem;
    SEQUENCE.forEach((item, index) => {
      if (index !== 0) {
        series[0].data.push([item.startTime, prevItem.intensity]);
        series[0].data.push([item.startTime + item.fade, item.intensity]);
      }
      prevItem = item;
    });
    // const series = [
    //   {
    //     name: "Light show sequence",
    //     data: [
    //       [0, 0],
    //       [5, 0], //M1
    //       [5, 100], //M1
    //       [10, 100], //M1M2
    //       [14, 75], //M2
    //       [20, 75], //M2M3
    //       [22, 25],
    //       [25, 25],
    //       [25, 0],
    //       [30, 0],
    //     ],
    //   },
    // ];
    const options = {
      xaxis: {
        type: "numeric",
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: ["#132666b3"],
    };

    return (
      <div className="chart">
        <Chart options={options} series={series} type="line" height="350" />
      </div>
    );
  };
  const getNewsSource = async () => {
    try {
      const resp = await fetch(
        "http://tsg.binaria.io:8080/tsg-basel2023/app/v1/news"
      );
      const data = await resp.json();
      setNewsSource(data.data.newsSource);
      console.log(data);
      console.log(newsSource);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  const getNotes = async (id) => {
    try {
      const resp = await fetch(
        `http://tsg.binaria.io:8080/tsg-basel2023/app/v1/notes?newsSourceId=${id}`
      );
      const data = await resp.json();
      console.log("notes", data);
      setNotes(data.data.notes);
    } catch (error) {
      console.error(error);
      const arr = [];
      setNotes(arr);
    }
  };

  const getArticleResponse = async (articleUrl, articleTitle, articleBody) => {
    try {
      let body = {
        newsSourceId: newsSource.id,
        url: articleUrl,
        title: articleTitle,
        body: articleBody,
      };
      let resp = await fetch(
        "http://tsg.binaria.io:8080/tsg-basel2023/app/v1/article",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      let data = await resp.json();

      console.log(data);
      if (data !== null) {
        calculate(data.data.article.id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const calculate = async (articleId) => {
    try {
      let resp = await fetch(
        `http://tsg.binaria.io:8080/tsg-basel2023/app/v1/calculate?${articleId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }
      );
      let data = await resp.json();
      if (data.data.message === "success") {
        getPerformancePlan(data.data.performanceId);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getPerformancePlan = async (performanceId) => {
    try {
      let resp = await fetch(
        `http://tsg.binaria.io:8080/tsg-basel2023/app/v1/performance?${performanceId}`
      );
      let data = await resp.json();
      console.log(data.data.csv);
      setPerformance(data.data.performance);
      setCsvContent(data.data.csv);
    } catch (error) {
      console.error(error);
    }
  };
  const getDownload = async () => {
    const anchorElement = document.createElement("a");
    anchorElement.href =
      "http://tsg.binaria.io:8080/tsg-basel2023/app/v1/download";
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
  };
  const sendBroadcastSignal = async () => {
    let resp = await fetch(
      "http://tsg.binaria.io:8080/tsg-basel2023/app/v1/broadcast"
    );
    let data = await resp.json();
    if (data.data.message !== "success") {
      alert(data.data.error);
    } else {
      setIsBroadcasted(true);
    }
  };
  // const getTranslatedUrl = (url) => {
  //   console.log(url);
  //   var link = url.split(".").join("-");
  //   if (link[link.length - 1] === "/")
  //     link = link.substring(0, link.length - 1);
  //   link = link.concat(
  //     ".translate.goog/?_x_tr_sch=http&_x_tr_sl=auto&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapp"
  //   );
  //   return link;
  //   // window.open(link, "_blank");
  // };
  const addNote = async () => {
    let newNote = document.getElementById("note-input").value;
    if (newNote !== null && newNote !== undefined && newNote !== "") {
      console.log("new", newNote);
      try {
        let body = {
          newsSourceId: newsSource.id,
          note: newNote,
        };
        let resp = await fetch(
          "http://tsg.binaria.io:8080/tsg-basel2023/app/v1/notes",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
          }
        );
        let data = await resp.json();
        getNotes(newsSource.id);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
      document.getElementById("note-input").value = "";
    }
  };
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const words = inputValue.split(/\s+/); // Split by whitespace characters

    if (words.length <= 3000) {
      setLimit(inputValue);
    } else {
      alert(
        "Article body is too long. Article body should not exceed 3000 words."
      );
    }
  };

  const previewModal = () => {
    return (
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        keyboard={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <ModalHeader closeButton>Preview</ModalHeader>
        <Modal.Body className="d-flex flex-column bg-dark">
          <div id="main-container">
            <div id="lamp-container">
              <div id="lamp">
                <div id="lamp-pole"></div>
                <div id="lamp-middle"></div>
                <div id="lamp-bottom"></div>
                <div id="glow"></div>
              </div>
            </div>
          </div>
          <div id="btn-container">
            <button
              className="btn btn-modal"
              onClick={() => {
                startShow(csvContent);
              }}
            >
              Start show
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  useEffect(() => {
    if (newsSource !== null) {
      console.log(newsSource);
      getNotes(newsSource.id);
      // getNotes(newsSource.id);
    }
  }, [newsSource]);

  return (
    <>
      {showModal && previewModal()}
      <div className="App p-3 gap-3">
        <header className="align-self-center">
          TSG - Untitled - Art Basel 2023
        </header>
        <main className="d-flex justify-content-center w-100">
          {showEnterData === false ? (
            <div
              id="main-form"
              className="d-flex flex-column w-75 rounded text-center p-3 py-5"
            >
              <div className=" rounded-top p-3 align-self-start">
                <p className="fw-bold">
                  Step 1: Select today's random news source
                </p>
              </div>
              {newsSource ? (
                <div className=" rounded-bottom p-2 d-flex flex-column align-items-center gap-5">
                  <div id="news-info" className="d-flex flex-row w-100">
                    <div className="w-50">
                      <p className="font-italic fs-6">
                        Randomly selected news source
                      </p>
                      <p className="fs-1 text-capitalize">{newsSource.name}</p>
                    </div>
                    <div className="w-50 d-flex flex-column gap-2 align-items-center">
                      {notes !== null && notes.length !== 0 ? (
                        <div
                          id="note-div"
                          className="w-75 align-self-center rounded h-100 overflow-auto  text-start no-scroll"
                        >
                          <ul className="d-flex flex-column gap-2">
                            {notes.map((note) => {
                              return (
                                <li className="fs-6 text-start" key={note.id}>
                                  {`(${moment(note.created_at).format(
                                    "DD MMM yyyy hh:mm"
                                  )}) ${note.text}`}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : (
                        <p className="fs-6 ">No notes for this news source.</p>
                      )}
                      <div className="d-flex w-75">
                        <input
                          type="text"
                          name="notes"
                          id="note-input"
                          className="roundLeft form-control"
                          placeholder="Note about news source..."
                        ></input>
                        <button
                          className="roundRight btn btn-primary w-25"
                          onClick={() => addNote()}
                        >
                          Add note
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row w-100 justify-items-center">
                    <div className="w-50 d-flex flex-column gap-3">
                      <div>
                        <button
                          className="btn btn-primary w-75 justify-self-center"
                          onClick={() => {
                            getNewsSource();
                          }}
                        >
                          Get random news source
                        </button>
                      </div>
                      <a href={newsSource.url} target="_blank" rel="noreferrer">
                        <button
                          className="btn btn-secondary  w-75"
                          onClick={() => setShowNextButton(true)}
                        >
                          Open news source in a new browser tab
                        </button>
                      </a>
                      {/* <a
                        href={getTranslatedUrl(newsSource.url)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button
                          className="btn btn-secondary w-75"
                          onClick={() => {
                            setShowNextButton(true);
                          }}
                        >
                          Open translated news source in a new browser tab
                        </button>
                      </a> */}
                    </div>
                    <div className="w-50 d-flex justify-content-end align-items-end">
                      {showNextButton && (
                        <button
                          className="btn btn-primary justify-self-end"
                          onClick={() => setShowEnterData(true)}
                        >
                          Next: Enter article data
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" rounded-bottom p-5">
                  <button
                    className="btn btn-primary w-25"
                    onClick={() => {
                      getNewsSource();
                    }}
                  >
                    Get random news source
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              {showReview === false ? (
                <div
                  id="main-form"
                  className="d-flex flex-column w-75 rounded px-3 py-5"
                >
                  <div className=" rounded-top p-3 ">
                    <p className="fw-bold">Step 2: Enter article data</p>
                  </div>
                  <div
                    id="article-input"
                    className="d-flex flex-column w-100 text-left p-3 gap-2"
                  >
                    <label>Article Url</label>
                    <input
                      type="text"
                      className="form-control"
                      id="article-url"
                    ></input>
                    <label>Article Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="article-title"
                    ></input>
                    <label>Article Body</label>
                    <textarea
                      rows={10}
                      type="text"
                      className="form-control"
                      id="article-body"
                      value={limit}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                  <div id="download" className="align-self-end p-3">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        let articleUrl =
                          document.getElementById("article-url").value;
                        let articleTitle =
                          document.getElementById("article-title").value;
                        let articleBody =
                          document.getElementById("article-body").value;
                        setShowReview(true);
                        getArticleResponse(
                          articleUrl,
                          articleTitle,
                          articleBody
                        );
                      }}
                    >
                      Next: Send to AI
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  id="main-form"
                  className="d-flex flex-column w-75 rounded px-3 py-5"
                >
                  {loading === false ? (
                    <>
                      {showDownload === false ? (
                        <>
                          <div className=" rounded-top p-3 ">
                            <p className="fw-bold">Step 3: Review</p>
                          </div>
                          <div
                            id="article-input"
                            className="d-flex flex-column w-100 text-left p-3 gap-2"
                          >
                            <LineChart data={csvContent} />
                          </div>
                          <div id="download" className="align-self-end px-3">
                            <button
                              className="btn btn-primary me-2"
                              onClick={() => {
                                setShowModal(true);
                              }}
                            >
                              Preview
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => setShowDownload(true)}
                            >
                              Next
                            </button>
                          </div>
                        </>
                      ) : (
                        <div>
                          <div>
                            <h3>
                              {moment(performance.date).format("DD MMM YYYY")}{" "}
                              Performances plan
                            </h3>
                            <h5>Article title: </h5>
                            <p>{performance.source.article_title}</p>
                            <h5>Performance duration: </h5>
                            <p>{performance.duration} seconds</p>
                            <h5>Performances:</h5>
                            {performance.events.map((item) => {
                              return (
                                <div className="lh-1 d-flex flex-column py-3">
                                  {" "}
                                  <p>Starting: {item.time}</p>
                                  <p className="">
                                    Location: {item.location_name}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                          <button
                            className="btn btn-primary me-2"
                            disabled={isBroadcasted}
                            onClick={() => {
                              sendBroadcastSignal();
                            }}
                          >
                            Broadcast
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              getDownload();
                            }}
                          >
                            Download CSV
                          </button>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="w-100 d-flex flex-column align-items-center justify-content-center gap-3 p-5">
                      <div
                        className="spinner-border"
                        style={{ width: "3rem", height: "3rem" }}
                        role="status"
                      ></div>
                      <span class="sr-only fw-bold">Calculating...</span>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </main>
      </div>
    </>
  );
}

export default App;
