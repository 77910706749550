const setStylesOnElement = function (styles, element) {
  try {
    Object.assign(element.style, styles);
  } catch (error) {
    console.error(error);
  }
};
function parseDuration(durationString) {
  const [hours, minutes, seconds] = durationString.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}
const timeout = (sequence, currentPosition) => {
  setTimeout(() => {
    setStylesOnElement(
      {
        transition: `background ${sequence[currentPosition].fade}s ease`,
        // background: `linear-gradient(0deg, rgba(246,255,35,0) 0%, rgba(240,255,25,${sequence[currentPosition].intensity}) 100%)`,
        background: `rgba(250, 235, 215,${sequence[currentPosition].intensity})`,
      },
      document.getElementById("glow")
    );

    currentPosition += 1;
    if (currentPosition < sequence.length) {
      timeout(sequence, currentPosition);
    }
    console.log(sequence[currentPosition]);
    // document.getElementById("command-label").innerHTML = JSON.stringify(
    //   sequence[currentPosition]
    // );
  }, sequence[currentPosition].duration * 1000);
};
// const CSV = `M1,[Exec 1.1 at 100 fade 2],0:00:03:00
// M2,[Exec 1.1 at 15 fade 0],0:00:05:00
// M3,[Exec 1.1 at 75 fade 0],0:00:07:00
// M4,[Exec 1.1 at 50 fade 0],0:00:10:00
// M5,[Exec 1.1 at 60 fade 0],0:00:12:00
// M6,[Exec 1.1 at 70 fade 0],0:00:17:00
// M7,[Exec 1.1 at 80 fade 0],0:00:22:00
// M8,[Exec 1.1 at 95 fade 0],0:00:27:00
// M9,[Exec 1.1 at 0 fade 0],0:00:33:00
// M10,[Exec 1.1 at 10 fade 0],0:00:36:00
// M11,[Exec 1.1 at 20 fade 2],0:00:41:00
// M12,[Exec 1.1 at 30 fade 2],0:00:47:00
// M13,[Exec 1.1 at 40 fade 2],0:00:53:00
// M14,[Exec 1.1 at 30 fade 3],0:00:59:00
// M15,[Exec 1.1 at 50 fade 4],0:01:02:00
// M16,[Exec 1.1 at 40 fade 3],0:01:06:00
// M17,[Exec 1.1 at 70 fade 5],0:01:09:00
// M18,[Exec 1.1 at 65 fade 3],0:01:14:00
// M19,[Exec 1.1 at 85 fade 5],0:01:17:00
// M20,[Exec 1.1 at 80 fade 3],0:01:22:00
// M21,[Exec 1.1 at 100 fade 2],0:01:25:00
// M22,[Exec 1.1 at 0 fade 0],0:01:27:00
// M23,[Exec 1.1 at 50 fade 0],0:01:33:00
// M24,[Exec 1.1 at 100 fade 0],0:01:38:00
// M25,[Exec 1.1 at 50 fade 5],0:01:43:00
// M26,[Exec 1.1 at 0 fade 5],0:01:53:00`;
// var previousDuration = 0;
// const SEQUENCE = CSV.split("\n").map((item) => {
//   const [name, info, unparsedDuration] = item.split(",");
//   let intensity = Number(info.match(/at (\d+)/)[1]);
//   intensity /= 100;
//   let fade = Number(info.match(/fade (\d+)/)[1]);
//   if (fade === 0) {
//     fade += 0.5;
//   }
//   const parsedDuration = parseDuration(unparsedDuration);
//   let duration = parsedDuration - previousDuration;
//   previousDuration = parsedDuration;
//   return { name, intensity, fade, duration };
// });

// console.log(SEQUENCE);

export default function startShow(data) {
  console.log(data);
  let arrayOfLines = data.split("\n");
  arrayOfLines.shift();
  arrayOfLines.pop();
  console.log(arrayOfLines);
  var previousDuration = 0;
  const SEQUENCE = arrayOfLines.map((item) => {
    const [name, info, unparsedDuration] = item.split(",");
    // debugger;
    let intensity = Number(info.match(/at (\d+)/)[1]);
    intensity /= 100;
    let fade = Number(info.match(/fade (\d+)/)[1]);
    const parsedDuration = parseDuration(unparsedDuration);
    let duration = parsedDuration - previousDuration;
    previousDuration = parsedDuration;
    return { name, intensity, fade, duration };
  });
  console.log(SEQUENCE);
  setStylesOnElement(
    {
      transition: `background ${SEQUENCE[0].fade}s ease`,
      //   background: `linear-gradient(0deg, rgba(246,255,35,0) 0%, rgba(240,255,25,${SEQUENCE[0].intensity}) 100%)`,
      background: `rgba(250, 235, 215,${SEQUENCE[0].intensity})`,
    },
    document.getElementById("glow")
  );
  timeout(SEQUENCE, 0);
}
